import { getLegal } from '@/lib/supabaseApi'
import { Divider, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from '../layout/Footer.module.css'
import { useState, useEffect } from 'react'
import { setCookie } from '@/lib/cookies'

export default function Legal() {
  const { t } = useTranslation()

  let [urls, setUrls] = useState<any>()

  useEffect(() => {
    getLegal().then((res) =>  {
      setUrls(res)
    })
  }, [])

  return (
    <Stack direction="row" className={styles.footer__legal}>
      <Link href={urls?.policy} target="_blank" rel="noreferrer">
        <Typography className={styles.link}>
          {t('common.privacy_cookies_policy')}
        </Typography>
      </Link>
      <Divider orientation="vertical" style={{ height: '1.5rem' }}/>
      <Link href={urls?.legal} target="_blank" rel="noreferrer">
        <Typography className={styles.link}>
          {t('common.legal_advise')}
        </Typography>
      </Link>
      <Divider orientation="vertical" style={{ height: '1.5rem' }}/>
      <Link href={urls?.cookies} target="_blank" rel="noreferrer">
        <Typography className={styles.link}>
          {t('common.cookies_policy')}
        </Typography>
      </Link>
      <Divider orientation="vertical" style={{ height: '1.5rem' }}/>
      <Link onClick={() => {setCookie('cookieBanner', 'false', 0); location.reload() }} sx={{ cursor: 'pointer'}}>
        <Typography className={styles.link}>
          {t('common.cookies_settings')}
        </Typography>
      </Link>
      {/* <Divider orientation="vertical" style={{ height: '1.5rem' }}/>
      <Link href={urls?.rsc} target="_blank" rel="noreferrer">
        <Typography className={styles.link}>
          {t('common.rsc_cert')}
        </Typography>
      </Link> */}
      <Divider orientation="vertical" style={{ height: '1.5rem'}} />
      <Link href={urls?.buy} target="_blank" rel="noreferrer">
        <Typography className={styles.link}>
          {t('common.terms_conditions')}
        </Typography>
      </Link>
    </Stack>
  )
}
