import { SOCIAL_LINKS } from '@/lib/constants'
import { Link, Stack, Typography } from '@mui/material'
import styles from '../layout/Footer.module.css'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook'

export default function Social() {
  return (
    <Stack direction="row" className={styles.footer__social}>
      <Link style={{ display: 'flex', textAlign: 'center', textDecoration: 'none', color: 'rgb(66, 66, 66)' }} href={SOCIAL_LINKS.instagram} target="_blank" rel="noreferrer">
        <InstagramIcon className={styles.instagram} />
        <Typography className={styles.link}>
          Instagram
        </Typography>
      </Link>
      <Link style={{ display: 'flex', textAlign: 'center', textDecoration: 'none', color: 'rgb(66, 66, 66)' }} href={SOCIAL_LINKS.tiktok} target="_blank" rel="noreferrer">
        <img src="/images/TikTok-logo.png" alt="Logo de Tiktok" width={20} />
        <Typography className={styles.link}>
          TikTok
        </Typography>
      </Link>
      <Link style={{ display: 'flex', textAlign: 'center', textDecoration: 'none', color: 'rgb(66, 66, 66)' }} href={SOCIAL_LINKS.youtube} target="_blank" rel="noreferrer">
        <YouTubeIcon className={styles.youtube} />
        <Typography className={styles.link}>
          Youtube
        </Typography>
      </Link>
      <Link style={{ display: 'flex', textAlign: 'center', textDecoration: 'none', color: 'rgb(66, 66, 66)' }} href={SOCIAL_LINKS.x} target="_blank" rel="noreferrer">
        <XIcon className={styles.x} />
        <Typography className={styles.link}>
          X
        </Typography>
      </Link>
      <Link style={{ display: 'flex', textAlign: 'center', textDecoration: 'none', color: 'rgb(66, 66, 66)' }} href={SOCIAL_LINKS.facebook} target="_blank" rel="noreferrer">
        <FacebookIcon className={styles.facebook} />
        <Typography className={styles.link}>
          Facebook
        </Typography>
      </Link>
    </Stack>
  )
}
