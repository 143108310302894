import { Wallet } from '@mui/icons-material'
import { Badge, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from '@/components/layout/Navbar.module.css'
import { definitions } from '@/interfaces/supabase'
import { useEffect, useState } from 'react'
import router from 'next/router'
import InviteFriendInfoModal from '../businessLayout/InviteFriendInfoModal'
import supabaseClient from '@/lib/supabaseClient'
import { useAuth } from 'context/Auth'

export default function UserWalletLinkIcon () {
  const { t } = useTranslation()

  const [userDB, setUserDB] = useState<definitions['users']>()
  const { user } = useAuth()

  const [anchorElWallet, setAnchorElWallet] = useState<null | HTMLElement>(null)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  
  const open = Boolean(anchorElWallet);
  const handleClickWallet = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElWallet(event.currentTarget);
  };
  const handleCloseWallet = () => {
    setAnchorElWallet(null);
  };

  useEffect(() => {
    getUserInfo()
  }, [])

  async function getUserInfo () {
    if (!user) return null

    const { data } = await supabaseClient.from<definitions['users']>('users')
      .select('id, referral_code, wallet_balance')
      .eq('supabase_user_id', user.id)
      .single()

    if (data) setUserDB(data)
  }

  return (
    <>
    {/* <Link href={'/invite_friend'}> */}
      <IconButton
        onClick={handleClickWallet}
        aria-label="invite_friend"
        size="large"
        color="primary"
        className={styles.nav__button}
        style={{ zIndex: 100 }}
      >
        <Badge color='primary' badgeContent={`${userDB?.wallet_balance || 0}€`} >
          <Wallet className={styles.nav__button__icon__auth} />
        </Badge>
      </IconButton>
    {/* </Link> */}

      <Menu
        id="basic-wallet-menu"
        anchorEl={anchorElWallet}
        open={open}
        onClose={handleCloseWallet}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={{ justifyContent: 'center' }}
          onClick={(e: any) => {
            e.preventDefault()
            router.push('/invite_friend')
          }}
        >
          <Typography style={{ cursor: 'pointer' }} className={styles.nav__button__text}>{t('common.invite_a_friend')}</Typography>
        </MenuItem>
        <MenuItem style={{ justifyContent: 'center' }}
          onClick={(e: any) => {
            e.preventDefault()
            setInfoModalOpen(true)
          }}
        >
          <Typography style={{ cursor: 'pointer' }} className={styles.nav__button__text}>{t('pages.invite_friend.wallet_history')}</Typography>
        </MenuItem>
      </Menu>
      {userDB?.id && <InviteFriendInfoModal userId={userDB!.id} open={infoModalOpen} setOpen={setInfoModalOpen} />}
    </>
  )
}
