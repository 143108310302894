import Header from './layout/Header'
import { ReactNode } from 'react'
import styles from './Layout.module.css'
import FooterLayout from './layout/FooterLayout'
import MarketplaceStack from './navigation/MarketplaceStack'
import { Button, Link, Typography, useMediaQuery } from '@mui/material'
import { MOBILE_WIDTH_LIMIT } from '@/lib/constants'
import { useTranslation } from 'react-i18next'

type PropsType = {
  children: ReactNode
}

export default function Layout({ children }: PropsType) {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)
  const heightDiff = 350

  return (
    <>
      <Header />
      {!isMobile && 
        // <div style={{ width: '90%', marginLeft: '5%', display: 'flex', justifyContent: 'space-evenly' }}>
        <div className={styles.carrousel_banner} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <MarketplaceStack />
          <Link href={'/custom-order'} style={{ paddingTop: '2px' }}>
            <Button>
              <Typography style={{ fontWeight: 'bold', fontSize: '0.85rem', width: 'max-content' }}>
                {t('button.custom_order')}
              </Typography>
            </Button>
          </Link>
        </div>
      }
      <main
        className={styles.main}
        style={{ minHeight: `calc(100vh - ${heightDiff}px)`, marginBottom: 30 }}
      >{children}</main>
      <div className={styles.carrousel_banner}>
        <FooterLayout />
      </div>
    </>
  )
}
