import { Button, IconButton, Link, Typography, useMediaQuery } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import styles from './MarketplaceSearchButton.module.css'
import { MOBILE_WIDTH_LIMIT, TABLET_WIDTH_LIMIT } from '@/lib/constants'

export default function MarketplaceSearchButton() {
  const { t } = useTranslation()
  const router = useRouter()
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)
  const isTablet = useMediaQuery(`(max-width:${TABLET_WIDTH_LIMIT}px)`)
  const isSearchView = router.pathname === '/search'

  if (isSearchView || isMobile)
    return null

  if (isTablet)
    return (
      <Link href="/search">
        <IconButton aria-label="user" size="large" color="primary" className={styles.nav__button}>
          <SearchIcon className={styles.nav__button__icon} />
        </IconButton>
      </Link>
    )

  return (
    <Button
      variant="outlined"
      className={styles.button}
      startIcon={<SearchIcon />}
      onClick={() => {
        router.push('/search')
      }}
    >
      <Typography variant="button">
        {t('common.search_marketplaces')}
      </Typography>
    </Button>
  )
}
