import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Typography } from '@mui/material'
import styles from './MenuHistory.module.css'
import { Dispatch, SetStateAction } from 'react'

type MenuHistoryProps = {
  setMenuIsExpanded: Dispatch<SetStateAction<Boolean>>
  activeCategory: String
}
export default function MenuHistory({ activeCategory, setMenuIsExpanded }: MenuHistoryProps) {
  const getBack = () => {
    setMenuIsExpanded(true)
  }
  return (
    <div className={styles.menu__history} onClick={getBack}>
      <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
      <Typography>{activeCategory}</Typography>
    </div>
  )
}
