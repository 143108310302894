import Logo from '@/components/common/Logo'
import Navbar from '@/components/layout/Navbar'
import styles from '@/components/layout/Header.module.css'
import { Stack, useMediaQuery } from '@mui/material'
import CategoriesMobile from './header/CategoriesMobile'
import { useEffect, useState } from 'react'
import MenuHistory from './header/MenuHistory'
import { useRouter } from 'next/router'
import { MOBILE_WIDTH_LIMIT } from '@/lib/constants'
import Image from 'next/image'
import { useAuth } from 'context/Auth'
import { definitions } from '@/interfaces/supabase'
import supabaseClient from '@/lib/supabaseClient'
import UserWalletLinkIcon from '../common/UserWalletLinkIcon'

export default function Header() {
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)
  const [menuIsExpanded, setMenuIsExpanded] = useState<Boolean>(false)
  const [activeCategory, setActiveCategory] = useState<String>('')
  const isMarketplacesPage = useRouter().asPath.includes('marketplaces')
  const { user } = useAuth()
  const [userDB, setUserDB] = useState<definitions['users']>()

  useEffect(() => {
    getUserInfo()
  }, [])

  async function getUserInfo () {
    if (!user) return null

    const { data } = await supabaseClient
      .from<definitions['users']>('users')
      .select('wallet_balance')
      .eq('supabase_user_id', user.id)
      .single()

    if (data) setUserDB(data)
  }

  return (
    <header>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} marginBottom={0}>
        <Logo />
        {isMobile && (<div style={{ width: '50%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10%'  }}>
            <div style={{ width: '70%', height: 50, paddingTop: '5%', alignSelf: 'center' }}>
              <Image style={{ zIndex: 100 }} src="/images/sequra-logo.png" alt="sequra" width={'191px'} height={'50px'} />
            </div>
            <div style={{ width: '30%' }}>
              <a href='https://www.confianzaonline.es/empresas/syniva.htm' target="_blank" rel="noopener noreferrer">
                <Image style={{ zIndex: 100 }} src="/images/confianza-online.png" alt="trustseal" width={'50%'} height={'50%'} />
              </a>
            </div>
          </div>
        </div>)}
        <Navbar menuIsExpanded={menuIsExpanded} setMenuIsExpanded={setMenuIsExpanded} />
      </Stack>

      {isMobile && (
        <>
          <div className={styles.container__header__up} style={{ marginBottom: 15 }}>
            {isMarketplacesPage && !menuIsExpanded &&
              <MenuHistory activeCategory={activeCategory} setMenuIsExpanded={setMenuIsExpanded} />
            }
          </div>
          <div className={`${styles.categories__mobile} ${menuIsExpanded && styles.expanded}`} id="menuList">
            <CategoriesMobile setMenuIsExpanded={setMenuIsExpanded} setActiveCategory={setActiveCategory} />
          </div>
        </>
      )
      }
    </header >
  )
}
