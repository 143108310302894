import { Box, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import { Stack } from '@mui/system'
import Legal from '../footer/legal'
import Social from '../footer/social'
import BadgesApp from '../footer/badges_app'
import { MOBILE_WIDTH_LIMIT } from '@/lib/constants'
import Image from 'next/image'
import styles from './Footer.module.css'

export default function FooterLayout() {
  const router = useRouter()
  const isHome = router.pathname === '/'
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)

  return isHome || isMobile ? null :
    <Stack
      direction={'row'}
      bottom={0}
      justifyContent={'space-between'}
    >
      <Box sx={{mr: 5, alignSelf: 'end' }} style={{ alignSelf: 'center '}}>
        <Social />
        <Legal />
      </Box>
      <BadgesApp />
      <a href='https://www.confianzaonline.es/empresas/syniva.htm' target="_blank" rel="noopener noreferrer">
        <Image className={styles.footer__trustseal} src="/images/confianza-online.png" alt="trustseal" width={'85%'} height={'85%'} />
      </a>
    </Stack>
}