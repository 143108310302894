import IconButton from '@mui/material/IconButton'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { Badge, Button, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import styles from './Navbar.module.css'
import MarketplaceSearchButton from '@/components/buttons/MarketplaceSearchButton'
import Link from 'next/link'
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import supabase from '@/lib/supabaseClient'
import { MOBILE_WIDTH_LIMIT } from '@/lib/constants'
import { getCartItemsNumber, getUserNotifications, getUserWalletInfo } from '@/lib/supabaseApi'
import { useAuth } from 'context/Auth'
import * as Sentry from '@sentry/browser'
import { definitions } from '@/interfaces/supabase'
import UserWalletLinkIcon from '../common/UserWalletLinkIcon'
import { Wallet } from '@mui/icons-material'

type NavbarProps = {
  menuIsExpanded: Boolean
  setMenuIsExpanded: Dispatch<SetStateAction<Boolean>>
  user?: definitions['users']
}

export default function Navbar({ menuIsExpanded, setMenuIsExpanded, user }: NavbarProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)
  let [authenticated, setAuthenticated] = useState(false)
  let [itemCount, setItemCount] = useState(0)
  let [userNotifications, setUserNotifications] = useState<any[]>([])
  const [userDB, setUserDB] = useState<definitions['users'] | null>()
  const { signOut } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl);
  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorEl(null);
  };

  const getWalletAmount = async () => {
    const userDB = await getUserWalletInfo()
    setUserDB(userDB)
  }

  useEffect(() => {
    setTimeout(() => {
      setAuthenticated(!!supabase.auth.user())
    }, 500) // This time is necessary to have the user properly loaded

    const getItemCount = async () => {
      const itemCount = await getCartItemsNumber()
      setItemCount(itemCount)
    }

    const getNotificationUser = async () => {
      const userNotifications = await getUserNotifications()
      setUserNotifications(userNotifications)
    }

    getItemCount()
    getNotificationUser()
    getWalletAmount()
  }, [router.asPath])

  const menuAction = () => setMenuIsExpanded(!menuIsExpanded)
  const closeMenu = () => setMenuIsExpanded(false)

  const logout = async () => {
    await signOut()
    router.push('/login')
    Sentry.setUser(null)
  }

  return (
    <>
      <MarketplaceSearchButton />

      <nav className={styles.nav}>
        {isMobile && (
          <>
            <Link href="#">
              <IconButton>
                <Typography onClick={menuAction} className={styles.nav__button__text}>
                  {t('common.menu')}
                </Typography>
              </IconButton>
            </Link>
            {/* <Link href="/">
              <IconButton
                onClick={closeMenu}
                aria-label="user"
                size="large"
                color="primary"
                className={styles.nav__button}
              >
                <HomeOutlinedIcon className={styles.nav__button__icon} />
              </IconButton>
            </Link> */}
            <Link href="/search">
              <IconButton
                onClick={closeMenu}
                aria-label="user"
                size="large"
                color="primary"
                className={styles.nav__button}
              >
                <SearchIcon className={styles.nav__button__icon} />
              </IconButton>
            </Link>
            
          </>
        )}

        {!isMobile && (
          <Link href="/help">
            <Button variant='text'>
              <Typography style={{ cursor: 'pointer' }} className={styles.nav__button__text}>{t('common.help')}</Typography>
            </Button>
          </Link>
        )}

        {authenticated ? (
          (!isMobile) ?
          <>
            <IconButton
              onClick={handleClickUser}
              aria-label="user"
              size="large"
              color="primary"
              className={styles.nav__button}
            >
              {(userNotifications.length > 0) ?
                <Badge badgeContent={userNotifications.length} color='error'>
                  <AccountCircleIcon className={styles.nav__button__icon__auth} />
                </Badge> : <AccountCircleIcon className={styles.nav__button__icon__auth} />
              }
            </IconButton>
            <Menu
              id="basic-user-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseUser}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem style={{ justifyContent: 'center' }}
                onClick={(e: any) => {
                  e.preventDefault()
                  router.push('/my_account/profile')
                }}
              >
                <Typography style={{ cursor: 'pointer' }} className={styles.nav__button__text}>{t('pages.profile.title')}</Typography>
              </MenuItem>
              <MenuItem style={{ justifyContent: 'center' }}
                onClick={(e: any) => {
                  e.preventDefault()
                  router.push('/my_account/orders')
                }}
              >
                <Typography style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} className={styles.nav__button__text}>
                  {t('pages.orders.title')}
                  {(userNotifications.length > 0) && <div style={{ marginLeft: 10, width: 25, height: 25, backgroundColor: '#d32f2f', borderRadius: '100%' }}>
                    <b style={{ color: 'whitesmoke', display: 'flex', height: 25, fontSize: 14, justifyContent: 'center', alignItems: 'center' }}>{userNotifications.length}</b>
                  </div>}
                </Typography>
              </MenuItem>
              <MenuItem style={{ justifyContent: 'center' }}
                onClick={(e: any) => {
                  e.preventDefault()
                  logout()
                }}
              >
                <LogoutIcon sx={{ mr: 1 }} /> <Typography style={{ cursor: 'pointer' }} className={styles.nav__button__text}>{t('pages.my_account.logout')}</Typography>
              </MenuItem>
            </Menu>

            <UserWalletLinkIcon />
          </>
          : <>
            <Link href={'/my_account'}>
                <IconButton
                onClick={closeMenu}
                aria-label="user"
                size="large"
                color="primary"
                className={styles.nav__button}
              >
                {(userNotifications.length > 0) ?
                  <Badge badgeContent={userNotifications.length} color='error'>
                    <AccountCircleIcon className={styles.nav__button__icon__auth} />
                  </Badge> : <AccountCircleIcon className={styles.nav__button__icon__auth} />
                }
              </IconButton>
            </Link>
            <Link style={{ marginLeft: '2rem', paddingLeft: '2rem' }} href={'/invite_friend'}>
            <IconButton
              onClick={closeMenu}
              aria-label="invite_friend"
              size="large"
              color="primary"
              className={styles.nav__button}
              style={{ zIndex: 100 }}
            >
              <Badge color='primary' badgeContent={`${userDB?.wallet_balance || 0}€`}>
                <Wallet className={styles.nav__button__icon__auth} />
              </Badge>
            </IconButton>
            </Link>
          </>
        ) : (
          <Link href="/login">
            <IconButton
              onClick={closeMenu}
              aria-label="user"
              size="large"
              color="primary"
              className={styles.nav__button}
            >
              <PersonOutlineOutlinedIcon className={styles.nav__button__icon} sx={{ strokeWidth: 1 }} />
            </IconButton>
          </Link>
        )}

        <Link href={authenticated ? '/cart' : '/login'}>
          <IconButton onClick={closeMenu} aria-label="cart" size="large" color="primary" className={styles.nav__button}>
            <Badge badgeContent={itemCount} color="secondary">
              <ShoppingCartOutlinedIcon className={styles.nav__button__icon} />
            </Badge>
          </IconButton>
        </Link>
      </nav>
    </>
  )
}
