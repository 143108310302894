import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from '../layout/Footer.module.css'
import Image from 'next/image'

export default function BadgesApp() {
  const { t } = useTranslation()

  return (
    <span className={styles.footer__badges}>
      <Typography className={styles.title} variant="button">
        {t('common.download_app')}
      </Typography>
      <a href="https://play.google.com/store/apps/details?id=com.syniva.syniva&gl=ES" target={'_blank'} rel="noreferrer" className={styles.badge}>
        <Image src="/images/google-play-badge.png" alt="google play badge" width={90} height={32} />
      </a>
      <a href="https://apps.apple.com/es/app/syniva/id6443994877" target={'_blank'} rel="noreferrer" className={styles.badge}>
        <Image src="/images/app-store-badge.svg" alt="app store badge" width={100} height={28} />
      </a>
      {/* HIDDEN FOR NOW... */}
      {/* <a href="" className={styles.badge}>
        <Image src="/images/chrome-badge.png" alt="chrome badge" width={120} height={38} />
      </a> */}
    </span>
  )
}
