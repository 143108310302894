import { Stack, styled, Typography, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import styles from './MarketplaceStack.module.css'
import Link from 'next/link'
import { Category, MarketplaceCategoriesWithChilds } from '@/types/supabase.types'
import { getMarketPlaceCategories } from '@/lib/supabaseApi'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { DEPARTMENT_NAME } from '@/lib/constants'

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}))

export default function MarketplaceStack() {
  const { t } = useTranslation()
  const [marketplaceCategories, setMarketplaceCategories] = useState<MarketplaceCategoriesWithChilds[]>()
  const [marketplaceCategoriesFiltered, setMarketplaceCategoriesFiltered] = useState<Category[]>([])
  const [activeCategory, setActiveCategory] = useState(0)

  useEffect(() => {
    getMarketPlaceCategories().then((categories) => {
      if (categories) {
        setMarketplaceCategories(categories)

        const depName = categories.find((c) => c.name.toLowerCase() === DEPARTMENT_NAME.toLowerCase())

        if (depName) {
          setActiveCategory(depName.id)
          setMarketplaceCategoriesFiltered(categories.find((c) => c.id === depName.id)?.childCategories || []
          )
        }
      }
    })
  }, [])

  function ParentMarketplaceCategories() {
    return (
      <Stack direction={'row'} justifyContent='center' flexWrap={'wrap'} spacing={2}>
        {marketplaceCategories?.map((category: any) => {
          if (category.name.toLowerCase() !== DEPARTMENT_NAME.toLowerCase()) {
            return <Link href={(category.url) ? category.url : `/marketplaces/${category.id}?${category.name}`} key={category.id}>
              <Item className={styles.stack__item}>
                {(category.name.toLowerCase() === 'amazon')
                  ? <img src="/images/amazon-logo.png" alt="AMAZON" width={80} />
                  : <Typography
                      // onMouseEnter={() => {
                      //   setActiveCategory(category.id)
                      //   setMarketplaceCategoriesFiltered(
                      //     marketplaceCategories.find((c) => c.id === category.id)?.childCategories || []
                      //   )
                      // }}
                      className={
                        (category.id === activeCategory && marketplaceCategoriesFiltered.length > 0) ?
                        styles.stack__item__text_active :
                        styles.stack__item__text}
                    >
                      {category.name}
                    </Typography>}
              </Item>
            </Link>
          }
        })
        }
        <Link href={'/marketplaces/all'}>
          <Item className={styles.stack__item}>
            <Typography className={styles.stack__item__text} sx={{ textTransform: 'uppercase', cursor: 'pointer' }}>
              {t('button.all_marketplaces')}
            </Typography>
          </Item>
        </Link>
      </Stack>
    )
  }

  function ChildMarketplaceCategories() {
    return (
      <div
        // onMouseLeave={() => {
        //   setMarketplaceCategoriesFiltered([])
        //   setActiveCategory(0)
        // }}
        className={styles.stack__subcategories}
        id="stack__subcategories">
        <Stack
          direction="row"
          justifyContent='center'
          spacing={3}
          // divider={<Divider orientation="vertical" flexItem />}
        >
          {marketplaceCategoriesFiltered.map((category) => (
            <Link key={category.id} href={`/marketplaces/${category.id}?${category.name}`}>
              <Item className={styles.stack__item}>
                <Typography className={styles.stack__item__text}>
                  {category.name}
                </Typography>
              </Item>
            </Link>
          ))}
        </Stack>
      </div >
    )
  }

  return (
    <Container maxWidth='lg' sx={{ mb: 6 }}>
      <ParentMarketplaceCategories />
      <ChildMarketplaceCategories />
      {/* {marketplaceCategoriesFiltered.length !== 0 && <ChildMarketplaceCategories />} */}
    </Container>
  )
}
