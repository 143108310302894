import { Box } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'
import styles from './Logo.module.css'

export default function Logo() {
  const router = useRouter()

  return (
    <Box className={styles.logo}>
      <Image
        onClick={() => router.push('/')}
        priority src="/images/logo.svg" alt="logo" layout="responsive" width={'300'} height={'142'} />
    </Box>
  )
}
